<template>
  <div>
    <breadcrumb :items="breadcrumbs"></breadcrumb>
    <v-card
      color="rgba(0,0,0,0)"
      dark
      max-width="500"
      class="mx-auto py-0 mt-10"
    >
      <span class="text-h6 third--text">{{ $t("users.edit.title2", { username: user.username }) }}</span>
    </v-card>
    <v-card class="mx-auto mt-4" max-width="500" dark color="fifth">
      <v-divider></v-divider>
      <v-card-text class="px-5">
        <user-form :user="user"></user-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Breadcrumb from "../commons/breadcrumb.vue";
import UserForm from "./form.vue";

export default {
  created() {
    this.setBreadcrumbs();
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    breadcrumbs: [],
  }),
  methods: {
    setBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("users.title"),
          to: this.$i18n.getRouteLink("user"),
          disabled: false,
          exact: true,
        },
        {
          text: this.$t("users.edit.title"),
          disabled: true,
        },
      ];
    },
  },
  components: {
    Breadcrumb,
    UserForm,
  },
};
</script>